iframe
<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)" style="height: 100%;">
    <!-- 首页版心 -->
    <div style="position: relative;">
  <img v-if="isOpen == true" src="@/assets/images/happy/HeaderWithAvatar.png" style="width: 100%;">
  <img v-else="isOpen == false" src="@/assets/images/happy/HeaderWithoutAvatar.png" style="width: 100%;">
  <button v-if="isOpen == true" style="position: absolute; top: 0; right: 0; width: 30px; height: 30px; background-color: transparent; border: none;" @click="handleClick"></button>
</div>
    <iframe id="myframe" src="https://happy5.fun/" style="width: 100%;height: 100%;">
    </iframe>
  </div>
  
</template>



<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        checkDialog: false,
        userInfoDialog: false,
        isOpen: false,
        data: {
          appName: "",
          userInfo: {
            uid: 0,
            goldNum: 0,
            inviteCode: "",
            appScheme: "",
            check: "",
            failMessage: ""
          }
        },
        contactDrawer: false,
        whatsApp: "https://chat.whatsapp.com/KsL4nGC3cBnCmrASm0trDc",
        meta: "https://www.facebook.com/groups/levelgame",
        telegram: "https://t.me/+JDzB_0CegZMwNWM9"
      };
    },
    methods: {
      handleClick(){
        this.$router.push('/PersonalCenter');
      },
      getIsOpen() {
        this.isLoading = true;
        this.$axios({
          url: "/webGift/getIsOp",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.isOpen = result.data.data.isOpen;
          } else if (result.data.code == 0) {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },

    },
    created() {
      if (this.$route.query.token != null) {
        localStorage.setItem("token", this.$route.query.token);
      }
      this.getIsOpen();
    }
  };
</script>

<style>
  #index-layout {
    width: 100%;
    height: 100%;
    /* background-image: url("@/assets/images/index/indexHeaderBg.png"); */
    background-repeat: no-repeat;
    background-size: 100%;
  }
  #index-layout .i-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
    /* color: white !important; */
    position: relative;
    z-index: 1;
  }
  #index-layout .el-page-header__left::after {
    display: none;
  }
  #index-layout .bottom-menu-bar .el-button {
    border: none;
    background: none;
  }
  #index-layout .el-drawer {
    border-radius: 20px 20px 0 0 !important;
  }
</style>
