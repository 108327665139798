<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-layout-ftfi">
      <div>
        <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/invite/BlockMagicAdventure-Main.png" style="width: 90%;margin-top: 2%;" />
        <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/invite/ftfi001-1.png" style="width: 100%;margin-top: -4%;" />
      </div>
      <div class="invite-footer" style="margin-top: 0%;">
        <div class="invite-code">
          {{$t('inviteFriend.i_code')}}：
          <div class="invite-code-text">
            <span @click="copyVal($event.target.innerText)" style="color: white;">{{inviteCode}}</span>
            <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
              <img src="@/assets/common/copyIconW.png" style="width: 12px;margin-bottom: 20px;" />
            </span>
          </div>
        </div> 

        <!-- <el-button v-loading="isLoadingBtn"  type="success"  style="border-radius: 10px;margin-bottom: 5%;"> 
        <el-button class="transparent-button">-->
          <img style="width: 80%;margin-top: 2%;" src="@/assets/images/invite/BlockMagicAdventureDownload.png" @click="goInvite()" alt="" />
          <!-- <img src="@/assets/images/invite/FoodTileFunIcon.png" style="width: 30px;border-radius: 6px;float: left;" />
          <span style="font-size: 18px;margin-left: 10px;line-height: 30px;color: white;">
            Food Tile Fun
            <el-icon class="el-icon-right" style="margin-left: 8px;font-weight: bold;"></el-icon>
          </span> 
        </el-button>
-->      </div>

      <el-dialog :visible.sync="isDownloadDialog" width="76%" center>
        <div style="text-align: center;background-color: white !important;">
          <div style="font-size: 20px;color: black;">
            {{$t('invite.i_havaDownloaded')}}
            <br />
            “Block Magic Adventure”？
          </div>
          <el-button round @click="goDownload()" style="background-color: yellow;color: black;margin-top: 30px;width: 60%;">
            {{$t('invite.i_goDownload')}}
          </el-button>
          <div @click="goApp()" style="text-decoration: underline;margin-top: 20px;color: rgba(161, 161, 161, 1);">
            {{$t('invite.i_downloaded')}}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        isLoadingBtn: false,
        webAppId: 20,
        inviteCode: "",
        isDownloadDialog: false
      };
    },
    mounted() {
      document
        .querySelector("body")
        .setAttribute("style", "background-color: rgba(72, 182, 137, 1);");
    },
    beforeDestroy() {
      document
        .querySelector("body")
        .removeAttribute("style", "background-color: rgba(72, 182, 137, 1);");
    },
    methods: {
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      goInvite() {
        this.isLoadingBtn = true;
        this.$axios({
          url: "/webGift/invite",
          method: "get",
          params: {
            webAppId: this.webAppId
          }
        }).then((result) => {
          this.isLoadingBtn = false;
          if (result.data.code == 1) {
            this.isDownloadDialog = true;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      goDownload() {
        window.location.href =
          "https://igameestudio.s3.ap-southeast-1.amazonaws.com/BlockMagicAdventure.html?referrer=" +
          this.inviteCode;
      },
      goApp() {
        window.location.href = "blockmagicadv://";
      }
    },
    created() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/invite",
        method: "get",
        params: {
          webAppId: this.webAppId
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
      this.inviteCode = this.$route.query.referrer;
    }
  };
</script>

<style>
  #invite-layout-ftfi {
    width: 100%;
    height: 100%;
  }
  #invite-layout-ftfi .invite-code {
    margin-bottom: 10%;
    font-size: 20px;
    color: white;
  }
  #invite-layout-ftfi .invite-code-text {
    color: rgba(19, 100, 62, 1);
    display: inline;
  }
  #invite-layout-ftfi .el-loading-mask {
    border-radius: 30px;
  }
  #invite-layout-ftfi .el-dialog {
    border-radius: 15px !important;
  }
  #invite-layout-ftfi .el-dialog__header {
    background: white !important;
    border-radius: 15px 15px 0 0 !important;
  }
  #invite-layout-ftfi .el-dialog__body {
    background: white !important;
    border-radius: 0 0 15px 15px !important;
  }
  .transparent-button {
    background-color: transparent;
    border: 1px solid transparent;
    color: #333; /* 设置按钮文本颜色 */
  }

  .transparent-button:hover {
    background-color: transparent;
    color: #666; /* 设置按钮鼠标悬停时的文本颜色 */
  }
</style>
