import Vue from "vue";
import VueRouter from "vue-router";
import PopItUpInvite from "@/views/invite/PopItUpInvite.vue";
import Convert from "@/views/Convert.vue";
import PersonalCenter from "@/views/PersonalCenter.vue";
import ConvertLog from "@/views/ConvertLog.vue";
import GoldLog from "@/views/GoldLog.vue";
import Index from "@/views/Index.vue";
import InviteFriend from "@/views/InviteFriend.vue";
import ConvertInfo from "@/views/ConvertInfo.vue";
import InviteLog from "@/views/InviteLog.vue";
import Task from "@/views/Task.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import PocketTankBattleInvite from "@/views/invite/PocketTankBattleInvite.vue";
import PopHigherInvite from "@/views/invite/PopHigherInvite.vue";
import FoodTileFunInvite from "@/views/invite/FoodTileFunInvite.vue";
import SwipeHeroInvite from "@/views/invite/SwipeHeroInvite.vue";
import KillerComingInvite from "@/views/invite/KillerComingInvite.vue";
import BearFriendsInvite from "@/views/invite/BearFriendsInvite.vue";
import PaintItAllInvite from "@/views/invite/PaintItAllInvite.vue";
import PocketCandyStoryInvite from "@/views/invite/PocketCandyStoryInvite.vue";
import BlockMagicAdventureinvite from "@/views/invite/BlockMagicAdventureInvite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: Index
  },
  {
    path: "/PersonalCenter",
    name: "PersonalCenter",
    component: PersonalCenter
  },
  {
    path: "/pop-it-up/invite",
    name: "PopItUpInvite",
    component: PopItUpInvite
  },
  {
    path: "/block-magic-adventure/invite",
    name: "BlockMagicAdventureInvite",
    component: BlockMagicAdventureinvite
  },
  {
    path: "/pocket-tank-battle/invite",
    name: "PocketTankBattleInvite",
    component: PocketTankBattleInvite
  },
  {
    path: "/pop-higher/invite",
    name: "PopHigherInvite",
    component: PopHigherInvite
  },
  {
    path: "/food-tile-fun/invite",
    name: "FoodTileFunInvite",
    component: FoodTileFunInvite
  },
  {
    path: "/swipe-hero/invite",
    name: "SwipeHeroInvite",
    component: SwipeHeroInvite
  },
  {
    path: "/killer-coming/invite",
    name: "KillerComingInvite",
    component: KillerComingInvite
  },
  {
    path: "/bear-friends/invite",
    name: "BearFriendsInvite",
    component: BearFriendsInvite
  },
  {
    path: "/paint-it-all/invite",
    name: "PaintItAllInvite",
    component: PaintItAllInvite
  },
  {
    path: "/pocket-candy-story/invite",
    name: "PocketCandyStoryInvite",
    component: PocketCandyStoryInvite
  },
  {
    path: "/convert",
    name: "convert",
    component: Convert
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/goldLog",
    name: "goldLog",
    component: GoldLog
  },
  {
    path: "/index",
    name: "index",
    component: Index
  },
  {
    path: "/inviteFriend",
    name: "inviteFriend",
    component: InviteFriend
  },
  {
    path: "/convertInfo/:id",
    name: "convertInfo",
    props: true,
    component: ConvertInfo
  },
  {
    path: "/inviteLog",
    name: "inviteLog",
    component: InviteLog
  },
  {
    path: "/task",
    name: "task",
    component: Task
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: ErrorPage
  }
];

const router = new VueRouter({
  routes
});

export default router;
