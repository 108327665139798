<template>
  <div style="background-color: black;height: 100%;">
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" style="background-color: #000;height: auto;" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="task-layout">
      <div class="t-page-header">
        <!-- <el-page-header :title="$t('common.i_back')" :content="$t('task.i_task')" @back="$router.push('/index')" style="float: left;"></el-page-header>
         -->
         <img src="@/assets/images/PersonlCenter/returnIcon.png" @click="goApp1()" style="width: 10px;float: left;" />
<!--         <div style="float: right;">
          <img src="@/assets/common/logIcon.png" style="width: 20px;margin-right: 2px;vertical-align: middle;" />
          <router-link to="/goldLog" style="text-decoration: none;color: white;vertical-align: middle;">{{$t('index.i_coinsLog')}}</router-link>
        </div> -->
      </div>

      <div style="margin-top: 2%;color: white;">{{$t('index.i_totalCoins')}}</div>

      <div style="margin-top: 4%" >
        <img src="@/assets/common/goldIcon.png" style="width: 30px;vertical-align: middle;" />
        <span style="font-size: 30px;color: rgba(240, 171, 52, 1) ;font-weight: bold;margin-left: 4px;vertical-align: middle;">{{$store.state.goldNum}}</span>
      </div>

      <div style="margin-top: 6%;">
        <el-button :class="taskShow==0?'taskAct':''" @click="taskShow=0" style="border: 1px solid rgba(62, 72, 103, 1);border-radius: 6px;min-width: 124px;">{{$t('task.i_dailyTasks')}}</el-button>
        <el-button :class="taskShow==1?'taskAct':''" @click="taskShow=1" style="border: 1px solid rgba(62, 72, 103, 1);border-radius: 6px;min-width: 124px;">{{$t('task.i_achievement')}}</el-button>
      </div>

      <div style="padding: 5%;color: white;" >
        <div v-if="taskShow == 0">
          <div v-for="task in taskData.dayTasks" :key="task.id" style="background-color: rgba(36, 36, 36, 1);margin-top: 3%;border-radius: 8px;padding: 2%;">
            <div style="overflow: hidden;">
              <div style="float: left;font-size: 16px;margin-top: 6px;margin-bottom: 6px;margin-left: 2%;width: 55%;word-wrap: break-word;">
                <div style="text-align: left;">{{task.taskTitle}}<span v-if="task.taskType != 1">({{task.currentNum}}/{{task.taskCondition}})</span></div>
                <div style="margin-top: 7px;text-align: left;">
                  <span style="vertical-align: middle;">+{{task.taskRewardGold}} </span>
                  <img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;" />
                </div>
              </div>
              <div style="float: right;margin-top: 12px;margin-right: 2%;">
                <el-button v-if="task.currentNum>=task.taskCondition" @click="goAppType=1;goApp()" style="background-color: rgba(255, 180, 20, 1);border-radius: 30px;color: black;border: none;min-width: 96px;">{{$t('task.i_getNow')}}</el-button>
                <el-button v-else @click="goAppType=0;goApp()" style="background-color: rgba(69, 182, 104, 1);border-radius: 30px;color: white;border: none;min-width: 96px;">{{$t('task.i_goNow')}}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="taskShow == 1">
          <div v-for="task in taskData.achievementTasks" :key="task.id" style="background-color: rgba(36, 36, 36, 1);margin-top: 3%;border-radius: 8px;padding: 2%;">
            <div style="overflow: hidden;">
              <div style="float: left;font-size: 16px;margin-top: 6px;margin-bottom: 6px;margin-left: 2%;width: 55%;word-wrap: break-word;">
                <div style="text-align: left;">{{task.taskTitle}}({{task.currentNum}}/{{task.taskCondition}})</div>
                <div style="margin-top: 7px;text-align: left;">
                  <span style="vertical-align: middle;">+{{task.taskRewardGold}} </span>
                  <img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;" />
                </div>
              </div>
              <div style="float: right;margin-top: 12px;margin-right: 2%;">
                <el-button v-if="task.currentNum>=task.taskCondition" @click="goAppType=1;goApp()" style="background-color: rgba(255, 180, 20, 1);border-radius: 30px;color: black;border: none;min-width: 96px;">{{$t('task.i_getNow')}}</el-button>
                <el-button v-else @click="goAppType=0;goApp()" style="background-color: rgba(0, 111, 255, 1);border-radius: 30px;color: white;border: none;min-width: 96px;">{{$t('task.i_goNow')}}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ task.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        checkDialog: false,
        taskShow: 0,
        taskData: {},
        urlSchemes: "",
        goAppType: 0,
        task: {
          appName: "",
          check: "",
          failMessage: "",
          appScheme: ""
        }
      };
    },
    mounted() {
      document.addEventListener("visibilitychange", this.isVisible);
    },
    destroyed() {
      document.removeEventListener("visibilitychange", this.isVisible);
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.task.check == "login") {
          window.location.href = this.task.appScheme + "login";
        } else {
          window.location.href = this.task.appScheme;
        }
      },
      getTaskList() {
        this.isLoading = true;
        this.$axios({
          url: "/webGift/task",
          method: "get",
          params: {
            pageNum: 1
          }
        }).then((result) => {
          this.isLoading = false;
          this.taskData = result.data.data;
          if (result.data.code == 1) {
            this.$store.state.goldNum = result.data.data.goldNum;
            this.urlSchemes = result.data.data.appScheme;
            this.task.appName = result.data.data.appName;
          } else if (result.data.code == -2) {
            this.task.check = result.data.data.check;
            this.task.failMessage = result.data.message;
            this.task.appScheme = result.data.data.appScheme;
            this.checkDialog = true;
          }
        });
      },
      goApp() {
        if (this.goAppType == 0) {
          window.location.href = this.urlSchemes + "task";
        } else if (this.goAppType == 1) {
          window.location.href = this.urlSchemes + "task";
        }
      },
      goApp1() {
        this.$router.push('/PersonalCenter');
      },
      isVisible: function (e) {
        if (e.target.visibilityState === "visible") {
          // 切入页面 显示页面
          this.getTaskList();
        } else if (e.target.visibilityState === "hidden") {
          // 切出页面 隐藏页面
        }
      }
    },
    created() {
      this.getTaskList();
    }
  };
</script>

<style>
  #task-layout {
    width: 100%;
    height: 100%;
  }
  #task-layout .t-page-header {
    overflow: hidden;
    padding: 4%;
  }
  #task-layout .taskAct {
    background-color: #45B668FF !important;
    border: 1px solid#45B668FF !important;
    color: white !important;
  }
  #task-layout .el-dialog {
    border-radius: 15px !important;
  }
  #task-layout .el-dialog__header {
    background: white !important;
    border-radius: 15px 15px 0 0 !important;
  }
  #task-layout .el-dialog__body {
    background: white !important;
    border-radius: 0 0 15px 15px !important;
  }
</style>
